import kaplay from "kaplay";
import "kaplay/global";
import initGameScene from "./gameScene.js";

kaplay();
initGameScene();

// Load all sprites
const SPRITES = {
    buttonSheet: { path: "sprites/menu/buttons.svg", options: { sliceX: 3, sliceY: 3 } },
    logo: { path: "sprites/menu/logo.svg" },
    background: { path: "sprites/menu/grassbackground.svg" }
};

Object.entries(SPRITES).forEach(([name, { path, options = {} }]) => {
    loadSprite(name, path, options);
});

// Button configurations
const BUTTONS = {
    play: { baseFrame: 0, y: -50, action: () => go("game") },
    quit: { baseFrame: 6, y: 50, action: () => window.close() }
};

function createBackground() {
    const tileSize = 80;
    const tilesX = Math.ceil(window.innerWidth / tileSize);
    const tilesY = Math.ceil(window.innerHeight / tileSize);
    
    for (let i = 0; i < tilesX; i++) {
        for (let j = 0; j < tilesY; j++) {
            add([
                sprite("background"),
                pos(i * tileSize, j * tileSize),
                scale(80/24),
            ]);
        }
    }
}

function createButton(type, baseFrame, yOffset) {
    const button = add([
        sprite("buttonSheet", { frame: baseFrame }),
        pos(window.innerWidth / 2, window.innerHeight / 2 + yOffset),
        anchor("center"),
        area(),
        scale(6),
    ]);

    button.onClick(() => {
        button.frame = baseFrame + 2;  // Click frame
        setTimeout(() => BUTTONS[type].action(), 50);
    });
    button.onHover(() => button.frame = baseFrame + 1);
    button.onHoverEnd(() => button.frame = baseFrame);
    
    return button;
}

scene("menu", () => {
    createBackground();

    // Add logo
    add([
        sprite("logo"),
        pos(window.innerWidth / 2, window.innerHeight / 4),
        anchor("center"),
        scale(5),
    ]);

    // Create menu buttons
    Object.entries(BUTTONS).forEach(([type, config]) => {
        createButton(type, config.baseFrame, config.y);
    });
});

// Start with menu scene
go("menu");