// PuzzleState class for both generation and solving
export class PuzzleState {
    constructor(player, boxes, walls, goal, pushCount = 0) {
        this.player = {x: player.x, y: player.y};  // Only copy needed properties
        this.boxes = boxes.map(({x, y}) => ({x, y}));
        this.walls = walls;  // Walls never change, no need to copy
        this.goal = {x: goal.x, y: goal.y};
        this.pushCount = pushCount;
    }

    getHash() {
        return `${this.player.x},${this.player.y}|${this.boxes
            .map(b => `${b.x},${b.y}`)
            .sort()
            .join('|')}`;
    }

    clone() {
        return new PuzzleState(this.player, this.boxes, this.walls, this.goal, this.pushCount);
    }
}

// Priority Queue implementation
class PriorityQueue {
    constructor() {
        this.heap = [];
    }
    
    enqueue(item, priority) {
        this.heap.push({item, priority});
        this._bubbleUp(this.heap.length - 1);
    }
    
    dequeue() {
        if (this.isEmpty()) return null;
        const result = this.heap[0];
        const end = this.heap.pop();
        if (this.heap.length > 0) {
            this.heap[0] = end;
            this._sinkDown(0);
        }
        return result;
    }

    isEmpty() {
        return this.heap.length === 0;
    }

    _bubbleUp(index) {
        const element = this.heap[index];
        while (index > 0) {
            const parentIndex = Math.floor((index - 1) / 2);
            const parent = this.heap[parentIndex];
            if (element.priority >= parent.priority) break;
            this.heap[index] = parent;
            this.heap[parentIndex] = element;
            index = parentIndex;
        }
    }

    _sinkDown(index) {
        const length = this.heap.length;
        const element = this.heap[index];

        while (true) {
            let swap = null;
            const leftChildIdx = 2 * index + 1;
            const rightChildIdx = 2 * index + 2;

            if (leftChildIdx < length) {
                if (this.heap[leftChildIdx].priority < element.priority) {
                    swap = leftChildIdx;
                }
            }

            if (rightChildIdx < length) {
                if ((swap === null && this.heap[rightChildIdx].priority < element.priority) ||
                    (swap !== null && this.heap[rightChildIdx].priority < this.heap[leftChildIdx].priority)) {
                    swap = rightChildIdx;
                }
            }

            if (swap === null) break;

            this.heap[index] = this.heap[swap];
            this.heap[swap] = element;
            index = swap;
        }
    }
}

// Seeded random number generator
export class Random {
    constructor(seed = Math.random()) {
        this.seed = seed;
    }

    random() {
        this.seed = (this.seed * 16807 + 1) % 2147483647;
        return (this.seed - 1) / 2147483646;
    }

    // Generate random integer between min and max (inclusive)
    randomInt(min, max) {
        return Math.floor(this.random() * (max - min + 1)) + min;
    }
}

// Helper functions
let _rng = new Random();
export const getRng = () => _rng;
export const setRng = (newRng) => { _rng = newRng; };

function randomInt(min, max) {
    return _rng.randomInt(min, max);
}

function manhattanDistance(p1, p2) {
    return Math.abs(p1.x - p2.x) + Math.abs(p2.x - p2.y);
}

// Get valid moves from current state
function getValidMoves(state, width, height, gridSize) {
    const moves = [];
    const directions = [{dx: 0, dy: 1}, {dx: 0, dy: -1}, {dx: 1, dy: 0}, {dx: -1, dy: 0}];
    
    for (const {dx, dy} of directions) {
        const newX = state.player.x + dx * gridSize;
        const newY = state.player.y + dy * gridSize;
        
        if (newX < 0 || newX >= width || newY < 0 || newY >= height) continue;
        if (isBlocked({x: newX, y: newY}, {walls: state.walls, boxes: []})) continue;
        
        const boxIndex = state.boxes.findIndex(b => b.x === newX && b.y === newY);
        
        if (boxIndex === -1) {
            moves.push({newPos: {x: newX, y: newY}, isBoxPush: false});
            continue;
        }
        
        const boxNewX = newX + dx * gridSize;
        const boxNewY = newY + dy * gridSize;
        
        // Add check to prevent pushing through goal
        const isGoalPosition = boxNewX === state.goal.x && boxNewY === state.goal.y;
        
        if (boxNewX >= 0 && boxNewX < width && 
            boxNewY >= 0 && boxNewY < height && 
            !isBlocked({x: boxNewX, y: boxNewY}, state) && 
            !isGoalPosition) {
            moves.push({
                newPos: {x: newX, y: newY},
                isBoxPush: true,
                boxIndex,
                boxNewPos: {x: boxNewX, y: boxNewY}
            });
        }
    }
    
    return moves;
}

// Fix the isBlocked function
export let rng = new Random();
function isBlocked(pos, state) {
    return state.walls.some(w => w.x === pos.x && w.y === pos.y) ||
           state.boxes.some(b => b.x === pos.x && b.y === pos.y);
}

// Export the solvePuzzle function
export function solvePuzzle(state, width, height, gridSize) {
    const visited = new Set();
    const queue = new PriorityQueue();
    const cameFrom = new Map();
    
    queue.enqueue(state, 0);
    
    while (!queue.isEmpty()) {
        const currentState = queue.dequeue().item;
        const stateHash = currentState.getHash();
        
        // Check if player can reach goal directly from current position
        if (currentState.player.x === currentState.goal.x && 
            currentState.player.y === currentState.goal.y && 
            !isBlocked(currentState.goal, currentState)) {
            // Reconstruct path
            const path = [];
            let current = currentState;
            while (current) {
                path.unshift(current);
                current = cameFrom.get(current.getHash());
            }
            return { solvable: true, pushCount: currentState.pushCount, path };
        }
        
        if (visited.has(stateHash)) continue;
        visited.add(stateHash);
        
        const moves = getValidMoves(currentState, width, height, gridSize);
        
        for (const move of moves) {
            const newState = currentState.clone();
            newState.player = move.newPos;
            
            if (move.isBoxPush) {
                newState.boxes[move.boxIndex] = move.boxNewPos;
                newState.pushCount++;
            }
            
            if (!visited.has(newState.getHash())) {
                cameFrom.set(newState.getHash(), currentState);
                const priority = newState.pushCount + 
                    (move.isBoxPush ? 2 : 1) * manhattanDistance(move.newPos, currentState.goal);
                queue.enqueue(newState, priority);
            }
        }
    }
    
    return { solvable: false, pushCount: Infinity };
}

// Level generation function
export function generateLevel(width, height, gridSize, options = {}) {
    const {
        minPushes = 10,
        minWalls = 3,
        maxWalls = 8,
        minBoxes = 1,
        maxBoxes = 3,
        maxAttempts = 1000000
    } = options;

    const generatePosition = (excludePositions = []) => {
        while (true) {
            const x = _rng.randomInt(0, width / gridSize - 1) * gridSize;
            const y = _rng.randomInt(0, height / gridSize - 1) * gridSize;
            if (!excludePositions.some(p => p.x === x && p.y === y)) {
                return {x, y};
            }
        }
    };

    for (let attempt = 0; attempt < maxAttempts; attempt++) {
        // Generate walls one by one
        const walls = [];
        const numWalls = _rng.randomInt(minWalls, maxWalls);
        for (let i = 0; i < numWalls; i++) {
            walls.push(generatePosition(walls));
        }
        
        // Generate boxes one by one
        const boxes = [];
        const numBoxes = _rng.randomInt(minBoxes, maxBoxes);
        for (let i = 0; i < numBoxes; i++) {
            boxes.push(generatePosition([...walls, ...boxes]));
        }
        
        const goal = generatePosition([...walls, ...boxes]);
        const player = generatePosition([...walls, ...boxes, goal]);
        
        const state = new PuzzleState(player, boxes, walls, goal);
        const solution = solvePuzzle(state, width, height, gridSize);
        
        if (solution.solvable && solution.pushCount >= minPushes) {
            return {state, solution};
        }
    }
    
    throw new Error("Could not generate valid level after maximum attempts");
}