import { PuzzleState, Random, generateLevel, getRng, setRng, solvePuzzle } from "./puzzleGenerator.js";

export default function initGameScene() {
    // Load all sprites
    loadSprite("tilemap", "sprites/sokoban_tilesheet.png", { 
        sliceX: 13, 
        sliceY: 8,
        anims: {
            idle: { from: 6, to: 6 },
            walkRight: { from: 78, to: 80 },
            walkLeft: { from: 81, to: 83 },
            walkUp: { from: 55, to: 57 },
            walkDown: { from: 52, to: 54 },
        }
    });

    loadSprite("player", "sprites/Racoon24x24.svg");
    loadSprite("tempTileMap", "sprites/Spritesheetkinda.svg",{
        sliceX: 5,
        sliceY: 5,
    });
    loadSprite("tile", "sprites/backgroundtiles.svg");
    loadSprite("wall", "sprites/updatedbrickwall6.svg");
    loadSprite("box", "sprites/box2.svg");
    loadSprite("keySheet", "sprites/keySheet.svg", {
        sliceX: 5,
        sliceY: 3,
        anims: {
            idle: { from: 0, to: 13, loop: true, speed: 10},
        }
    });
    loadSprite("playerSheet", "sprites/RacoonSheet.svg", {
        sliceX: 2,
        sliceY: 1,
        anims: {
            idle: { from: 0, to: 1, loop: true, speed: 2},
        }
    });

    // Update loading sprite definition
    loadSprite("loading", "sprites/menu/load.svg", {
        sliceX: 20,
        sliceY: 1,
        anims: {
            spin: { 
                from: 0,
                to: 19,
                loop: true,
                pingpong: false,
                speed: 24
            }
        }
    });

    // Make sure gameBackground is loaded before it's used
    loadSprite("gameBackground", "sprites/randombackground.svg");

    // Update the refocusGame function
    function refocusGame() {
        const canvas = document.querySelector('canvas');
        if (canvas) {
            canvas.focus();
        }
    }

    scene("game", () => {
        // Move shared functions and variables to scene scope
        let menuButton;
        let generateButton, seedInput, loadSeedButton, copySeedButton, solverButton, retryButton;
        let buttonsGenerated = false;

        // Move utility functions to scene scope
        function styleButton(button, bgColor) {
            button.style.padding = '10px 20px';
            button.style.fontSize = '16px';
            button.style.cursor = 'pointer';
            button.style.backgroundColor = bgColor;
            button.style.color = '#fff';
            button.style.border = 'none';
            button.style.borderRadius = '5px';
            button.style.boxShadow = '0 2px 4px rgba(0,0,0,0.3)';
            button.style.userSelect = 'none';
            button.style.WebkitUserSelect = 'none';
            button.style.outline = 'none';
        }

        function removeUtilityButtons() {
            if (generateButton) document.body.removeChild(generateButton);
            if (seedInput) document.body.removeChild(seedInput);
            if (loadSeedButton) document.body.removeChild(loadSeedButton);
            if (copySeedButton) document.body.removeChild(copySeedButton);
            if (solverButton) document.body.removeChild(solverButton);
            if (retryButton) document.body.removeChild(retryButton);
            generateButton = null;
            seedInput = null;
            loadSeedButton = null;
            copySeedButton = null;
            solverButton = null;
            retryButton = null;
        }

        function createMenuButton() {
            if (menuButton) return;
            menuButton = document.createElement('button');
            menuButton.innerText = 'Back'; 
            menuButton.style.position = 'absolute';
            menuButton.style.bottom = '20px';
            menuButton.style.left = '20px';
            menuButton.style.display = 'none';
            styleButton(menuButton, '#6c757d');
            
            menuButton.addEventListener('click', () => {
                removeUtilityButtons();
                if (document.body.contains(menuButton)) {
                    document.body.removeChild(menuButton);
                }
                refocusGame();
                go("menu");
            });
            
            document.body.appendChild(menuButton);
        }

        // Focus the canvas when the scene starts
        wait(0.1, () => {
            const canvas = document.querySelector('canvas');
            if (canvas) {
                canvas.focus();
            }
        });

        // Add level cache system at the top of the scene
        const levelCache = [];
        const MAX_CACHED_LEVELS = 6;
        let isGeneratingLevel = false;
        let totalLevelsGenerated = 0;  // Add counter for total levels

        // Function to request a new background level (now with measurements passed in)
        function requestBackgroundLevel(gridWidth, gridHeight, gridSquareSize) {
            if (isGeneratingLevel || levelCache.length >= MAX_CACHED_LEVELS) return;
            
            isGeneratingLevel = true;
            console.log(`🔄 Generating new level... (Cache: ${levelCache.length}/${MAX_CACHED_LEVELS})`);
            const backgroundWorker = new Worker(new URL('./levelWorker.js', import.meta.url), { type: 'module' });
            
            backgroundWorker.postMessage({
                width: gridWidth,
                height: gridHeight,
                gridSize: gridSquareSize,
                options: {
                    minPushes: 5,
                    minWalls: 2,
                    maxWalls: 5,
                    minBoxes: 1,
                    maxBoxes: 2
                },
                seed: Math.random()
            });

            backgroundWorker.onmessage = (e) => {
                backgroundWorker.terminate();
                isGeneratingLevel = false;
                
                if (e.data.success) {
                    levelCache.push({
                        state: e.data.result.state,
                        seed: e.data.seed
                    });
                    totalLevelsGenerated++;
                    console.log(`✅ Level generated successfully! Total: ${totalLevelsGenerated} (Cache: ${levelCache.length}/${MAX_CACHED_LEVELS})`);
                    // Request another level if cache isn't full
                    if (levelCache.length < MAX_CACHED_LEVELS) {
                        requestBackgroundLevel(gridWidth, gridHeight, gridSquareSize);
                    }
                } else {
                    console.log('❌ Level generation failed, retrying...');
                    requestBackgroundLevel(gridWidth, gridHeight, gridSquareSize);
                }
            };
        }

        // const backgroundTileAmountX = Math.ceil(window.innerWidth / (24*80/24));
        // const backgroundTileAmountY = Math.ceil(window.innerHeight / (24*80/24));
        // for (let i = 0; i < backgroundTileAmountX; i++) {
        //     for (let j = 0; j < backgroundTileAmountY; j++) {
        //         add([
        //             sprite("gameBackground"),
        //             pos(i * 24*80/24, j * 24*80/24),
        //             scale(80/24),
        //         ]);
        //     }
        // }

        // Add worker initialization at the start of the scene
        const levelWorker = new Worker(new URL('./levelWorker.js', import.meta.url), { type: 'module' });

        // Create loading screen using menu style
        const createMenuBackground = () => {
            const backgroundTileAmountX = Math.ceil(window.innerWidth / (24*80/24));
            const backgroundTileAmountY = Math.ceil(window.innerHeight / (24*80/24));
            for (let i = 0; i < backgroundTileAmountX; i++) {
                for (let j = 0; j < backgroundTileAmountY; j++) {
                    add([
                        sprite("background"),
                        pos(i * 24*80/24, j * 24*80/24),
                        scale(80/24),
                        z(100),
                    ]);
                }
            }
        };

        // Add function to create menu button
        function createMenuButton() {
            if (menuButton) return; // Prevent duplicate creation
            menuButton = document.createElement('button');
            menuButton.innerText = 'Back'; 
            menuButton.style.position = 'absolute';
            menuButton.style.bottom = '20px';
            menuButton.style.left = '20px';
            menuButton.style.display = 'none';  // Hide button initially
            styleButton(menuButton, '#6c757d');
            
            menuButton.addEventListener('click', () => {
                removeUtilityButtons();
                if (document.body.contains(menuButton)) {
                    document.body.removeChild(menuButton);
                }
                refocusGame();
                go("menu");
            });
            
            document.body.appendChild(menuButton);
        }

        // Create loading screen elements
        const loadingElements = {
            logo: add([
                sprite("logo"),
                pos(window.innerWidth / 2, window.innerHeight / 4),
                anchor("center"),
                scale(5),
                z(101)
            ]),
            anim: add([
                sprite("loading"),
                pos(window.innerWidth / 2, window.innerHeight / 2),
                anchor("center"),
                scale(10),
                z(101),
                "loadingAnim",  // Add tag for identification
                {
                    add() {
                        this.play("spin");  // Start animation when object is added
                    }
                }
            ])
        };

        createMenuBackground();

        // Function to safely destroy loading elements
        const destroyLoadingScreen = () => {
            get().forEach((obj) => {
                if (obj.z >= 100) { // Destroy everything with z-index >= 100 (loading screen elements)
                    destroy(obj);
                }
            });
        };

        // Defer game initialization to next frame
        wait(0.1, () => {
            // Game state variables (move these up)
            let player, boxes, unmovables, winBlock;
            let currentLevel;
            let currentSeed;
            let winText; 
            let loadingText; 
            let isSolutionRunning = false;
            let gameCompleted = false;
            let solutionInterval = null;
            let initialState = null;

            // Grid measurements
            let windowWidth = width();
            let windowHeight = height();
            const gridSizeY = 5;
            const gridSizeX = 5;
            let gridSquareSize, gridWidth, gridHeight, offsetX, offsetY;
            
            // Add a background update function
            function updateBackground() {
                destroyAll("mainBackground");
                const bg = add([
                    rect(width(), height()),
                    pos(0, 0),
                    color(0, 0, 0),
                    z(-2),
                    "mainBackground",
                ]);
                return bg;
            }

            // Function to update grid measurements
            function updateGridMeasurements() {
                // Store current grid positions before updating measurements
                const oldGridWidth = gridWidth;
                const oldGridHeight = gridHeight;
                const oldOffsetX = offsetX;
                const oldOffsetY = offsetY;

                // Update measurements
                windowWidth = width();
                windowHeight = height();
                gridSquareSize = Math.floor(windowHeight / (gridSizeY + 0.5));
                gridWidth = gridSizeX * gridSquareSize;
                gridHeight = gridSizeY * gridSquareSize;
                offsetX = (windowWidth - gridWidth) / 2;
                offsetY = gridSquareSize * 0.25;

                // Update background first - make sure to destroy ALL old backgrounds
                destroyAll("mainBackground");
                destroyAll("background"); // Add this line to catch all background elements
                add([
                    rect(windowWidth, windowHeight),
                    pos(0, 0),
                    color(0, 0, 0),
                    z(-2),
                    "mainBackground",
                ]);

                // Update game objects with relative positioning
                if (player) {
                    const relativeX = (player.pos.x - oldOffsetX) / oldGridWidth;
                    const relativeY = (player.pos.y - oldOffsetY) / oldGridHeight;
                    player.pos = vec2(
                        offsetX + (relativeX * gridWidth),
                        offsetY + (relativeY * gridHeight)
                    );
                    player.scaleTo(gridSquareSize / 24);
                }

                boxes?.forEach(box => {
                    const relativeX = (box.pos.x - oldOffsetX) / oldGridWidth;
                    const relativeY = (box.pos.y - oldOffsetY) / oldGridHeight;
                    box.pos = vec2(
                        offsetX + (relativeX * gridWidth),
                        offsetY + (relativeY * gridHeight)
                    );
                    box.box.scaleTo(gridSquareSize / 24);
                });

                unmovables?.forEach(wall => {
                    const relativeX = (wall.pos.x - oldOffsetX) / oldGridWidth;
                    const relativeY = (wall.pos.y - oldOffsetY) / oldGridHeight;
                    wall.pos = vec2(
                        offsetX + (relativeX * gridWidth),
                        offsetY + (relativeY * gridHeight)
                    );
                    wall.unmovable.scaleTo(gridSquareSize / 24);
                });

                if (winBlock) {
                    const relativeX = (winBlock.pos.x - oldOffsetX) / oldGridWidth;
                    const relativeY = (winBlock.pos.y - oldOffsetY) / oldGridHeight;
                    winBlock.pos = vec2(
                        offsetX + (relativeX * gridWidth),
                        offsetY + (relativeY * gridHeight)
                    );
                    winBlock.winBlock.scaleTo(gridSquareSize / 24);
                }

                // Update grid tiles
                destroyAll("gridTile");
                for (let i = 0; i < gridSizeX; i++) {
                    for (let j = 0; j < gridSizeY; j++) {
                        add([
                            pos(offsetX + i * gridSquareSize, offsetY + j * gridSquareSize),
                            sprite("tile"),
                            scale(vec2(gridSquareSize / 24)),
                            z(-1),
                            "gridTile"
                        ]);
                    }
                }
            }

            // Initial grid setup
            updateGridMeasurements();

            // Rest of the game initialization
            // Move all these variables to the top of the initialization
            // let windowWidth = width();
            // let windowHeight = height();
            // const gridSizeY = 5; // Keep fixed number of rows
            // const gridSizeX = 5; // Keep same number of columns as rows for now
            // let gridSquareSize, gridWidth, gridHeight, offsetX, offsetY;
            
            // Function to update grid measurements
            function updateGridMeasurements() {
                windowWidth = width();
                windowHeight = height();
                gridSquareSize = Math.floor(windowHeight / (gridSizeY + 0.5));
                gridWidth = gridSizeX * gridSquareSize;
                gridHeight = gridSizeY * gridSquareSize;
                offsetX = (windowWidth - gridWidth) / 2;
                offsetY = gridSquareSize * 0.25;

                // Update all game objects with new measurements
                if (player) {
                    const playerGridX = Math.round((player.pos.x - offsetX) / (gridWidth / gridSizeX));
                    const playerGridY = Math.round((player.pos.y - offsetY) / (gridHeight / gridSizeY));
                    player.pos.x = offsetX + playerGridX * gridSquareSize;
                    player.pos.y = offsetY + playerGridY * gridSquareSize;
                    player.scaleTo(gridSquareSize / 24);
                }

                boxes?.forEach(box => {
                    const boxGridX = Math.round((box.pos.x - offsetX) / (gridWidth / gridSizeX));
                    const boxGridY = Math.round((box.pos.y - offsetY) / (gridHeight / gridSizeY));
                    box.pos.x = offsetX + boxGridX * gridSquareSize;
                    box.pos.y = offsetY + boxGridY * gridSquareSize;
                    box.box.scaleTo(gridSquareSize / 24);
                });

                unmovables?.forEach(wall => {
                    const wallGridX = Math.round((wall.pos.x - offsetX) / (gridWidth / gridSizeX));
                    const wallGridY = Math.round((wall.pos.y - offsetY) / (gridHeight / gridSizeY));
                    wall.pos.x = offsetX + wallGridX * gridSquareSize;
                    wall.pos.y = offsetY + wallGridY * gridSquareSize;
                    wall.unmovable.scaleTo(gridSquareSize / 24);
                });

                if (winBlock) {
                    const winGridX = Math.round((winBlock.pos.x - offsetX) / (gridWidth / gridSizeX));
                    const winGridY = Math.round((winBlock.pos.y - offsetY) / (gridHeight / gridSizeY));
                    winBlock.pos.x = offsetX + winGridX * gridSquareSize;
                    winBlock.pos.y = offsetY + winGridY * gridSquareSize;
                    winBlock.winBlock.scaleTo(gridSquareSize / 24);
                }

                // Update grid tiles
                destroyAll("gridTile");
                for (let i = 0; i < gridSizeX; i++) {
                    for (let j = 0; j < gridSizeY; j++) {
                        add([
                            pos(offsetX + gridSquareSize * i, offsetY + gridSquareSize * j),
                            sprite("tile"),
                            scale(gridSquareSize / 24),
                            z(-1),
                            "gridTile"
                        ]);
                    }
                }
            }

            // Initial grid setup
            updateGridMeasurements();

            // Remove these duplicate declarations
            // const gridSquareSize = Math.floor(windowHeight / (gridSizeY + 0.5));
            // const gridWidth = gridSizeX * gridSquareSize;
            // const gridHeight = gridSizeY * gridSquareSize;
            // const offsetX = (windowWidth - gridWidth) / 2;
            // const offsetY = gridSquareSize * 0.25;

            // Add background
            add([
                rect(windowWidth, windowHeight),
                pos(0, 0),
                color(0, 0, 0),
                z(-2),
                "mainBackground"
            ]);

            // Replace the menu button creation with a DOM button
            const menuButton = document.createElement('button');
            menuButton.innerText = 'Back';
            menuButton.style.position = 'absolute';
            menuButton.style.bottom = '20px';
            menuButton.style.left = '20px';
            styleButton(menuButton, '#6c757d');  // Use the same styling function as other buttons
            menuButton.style.display = 'none';  // Hide the button initially
            document.body.appendChild(menuButton);

            menuButton.addEventListener('click', () => {
                removeUtilityButtons();
                document.body.removeChild(menuButton);  // Remove menu button before scene change
                refocusGame();
                go("menu");
            });

            // Game variables
            // let player, boxes, unmovables, winBlock;
            // let currentLevel;
            // let currentSeed;
            // let winText; 
            // let loadingText; 
            // let isSolutionRunning = false;
            // let gameCompleted = false;
            // let solutionInterval = null;

            // Add initial state storage
            // let initialState = null;

            // Add utility buttons
            function styleButton(button, bgColor) {
                button.style.padding = '10px 20px';
                button.style.fontSize = '16px';
                button.style.cursor = 'pointer';
                button.style.backgroundColor = bgColor;
                button.style.color = '#fff';
                button.style.border = 'none';
                button.style.borderRadius = '5px';
                button.style.boxShadow = '0 2px 4px rgba(0,0,0,0.3)';
                button.style.userSelect = 'none';  // Prevent text selection
                button.style.WebkitUserSelect = 'none';  // For Safari
                button.style.outline = 'none';  // Remove focus outline
            }

            // Add a helper function to refocus game
            function refocusGame() {
                window.focus();
                document.activeElement.blur();  // Remove focus from any focused element
            }

            // Create references for utility buttons
            let generateButton, seedInput, loadSeedButton, copySeedButton, solverButton, retryButton;
            let buttonsGenerated = false;

            // Function to create utility buttons
            function createUtilityButtons() {
                if (buttonsGenerated) return;
                // Create utility buttons
                generateButton = document.createElement('button');
                generateButton.innerText = 'Generate New Level';
                generateButton.style.position = 'absolute';
                generateButton.style.top = '20px';
                generateButton.style.left = '20px';
                styleButton(generateButton, '#28a745');
                document.body.appendChild(generateButton);

                seedInput = document.createElement('input');
                seedInput.type = 'text';
                seedInput.placeholder = 'Enter seed';
                seedInput.style.position = 'absolute';
                seedInput.style.top = '20px';
                seedInput.style.right = '170px';
                seedInput.style.padding = '10px';
                seedInput.style.width = '150px';
                document.body.appendChild(seedInput);

                loadSeedButton = document.createElement('button');
                loadSeedButton.innerText = 'Load Seed';
                loadSeedButton.style.position = 'absolute';
                loadSeedButton.style.top = '60px';
                loadSeedButton.style.right = '170px';
                styleButton(loadSeedButton, '#dc3545');
                document.body.appendChild(loadSeedButton);

                copySeedButton = document.createElement('button');
                copySeedButton.innerText = 'Copy Seed';
                copySeedButton.style.position = 'absolute';
                copySeedButton.style.top = '100px';
                copySeedButton.style.right = '170px';
                styleButton(copySeedButton, '#6c757d');
                document.body.appendChild(copySeedButton);

                // Add solution button
                solverButton = document.createElement('button');
                solverButton.innerText = 'Show Solution';
                solverButton.style.position = 'absolute';
                solverButton.style.top = '60px';
                solverButton.style.left = '20px';
                styleButton(solverButton, '#007bff');
                document.body.appendChild(solverButton);

                // Create Retry button
                retryButton = document.createElement('button');
                retryButton.innerText = 'Retry Level';
                retryButton.style.position = 'absolute';
                retryButton.style.top = '100px'; // Position under 'Show Solution' button
                retryButton.style.left = '20px';
                styleButton(retryButton, '#ffc107');
                document.body.appendChild(retryButton);

                // Add button event listeners
                generateButton.addEventListener('click', () => {
                    // Pass undefined explicitly to use cache
                    createLevel(undefined);
                    refocusGame();
                });
                
                loadSeedButton.addEventListener('click', () => {
                    const inputValue = seedInput.value.replace(/,/g, '.');
                    const seed = parseFloat(inputValue);
                    if (!isNaN(seed) && seed >= 0) {
                        createLevel(seed);
                        refocusGame();
                    }
                });

                copySeedButton.addEventListener('click', () => {
                    if (currentSeed !== undefined) {
                        const seedStr = currentSeed.toString();
                        navigator.clipboard.writeText(seedStr)
                            .then(() => {
                                const originalText = copySeedButton.innerText;
                                copySeedButton.innerText = 'Copied!';
                                setTimeout(() => {
                                    copySeedButton.innerText = originalText;
                                    refocusGame();
                                }, 1000);
                            });
                    }
                    refocusGame();
                });

                // Add solver functionality
                solverButton.addEventListener('click', () => {
                    // Don't run if solution is already running or game is completed
                    if (!currentLevel || isSolutionRunning || gameCompleted) return;

                    // Clear any existing error messages
                    get("solutionError").forEach(obj => destroy(obj));
                    
                    isSolutionRunning = true;
                    
                    try {
                        const state = new PuzzleState(
                            { x: player.pos.x - offsetX, y: player.pos.y - offsetY },
                            boxes.map(b => ({ x: b.pos.x - offsetX, y: b.pos.y - offsetY })),
                            unmovables.map(u => ({ x: u.pos.x - offsetX, y: u.pos.y - offsetY })),
                            { x: winBlock.pos.x - offsetX, y: winBlock.pos.y - offsetY }
                        );

                        const { path } = solvePuzzle(state, gridWidth, gridHeight, gridSquareSize);

                        if (path && path.length > 0) {
                            let stepIndex = 0;

                            solutionInterval = setInterval(() => {
                                if (!player || stepIndex >= path.length) {
                                    clearInterval(solutionInterval);
                                    solutionInterval = null;
                                    isSolutionRunning = false;
                                    return;
                                }

                                const state = path[stepIndex];
                                
                                player.pos.x = state.player.x + offsetX;
                                player.pos.y = state.player.y + offsetY;

                                state.boxes.forEach((boxPos, index) => {
                                    if (boxes[index]) {  // Check if box exists
                                        boxes[index].pos.x = boxPos.x + offsetX;
                                        boxes[index].pos.y = boxPos.y + offsetY;
                                    }
                                });

                                stepIndex++;
                            }, 500);
                        } else {
                            add([
                                text("No solution found!", {
                                    size: 32,
                                    width: 300,
                                }),
                                pos(center()),
                                anchor("center"),
                                color(255, 0, 0),
                                opacity(1),  // Add opacity component
                                lifespan(2),
                                "solutionError",  // Add tag to track error messages
                            ]);
                        }
                    } catch (error) {
                        console.error("Solver error:", error);
                        isSolutionRunning = false;
                    }
                    
                    if (!solutionInterval) {
                        isSolutionRunning = false;
                    }
                    refocusGame();
                });

                // Add event listener to Retry button
                retryButton.addEventListener('click', () => {
                    resetLevel();
                    refocusGame();
                });

                // Make seed input refocus game on enter
                seedInput.addEventListener('keyup', (e) => {
                    if (e.key === 'Enter') {
                        const inputValue = seedInput.value.replace(/,/g, '.');
                        const seed = parseFloat(inputValue);
                        if (!isNaN(seed) && seed >= 0) {
                            createLevel(seed);
                            refocusGame();
                        }
                    }
                });

                // Prevent seed input from taking focus on mobile
                seedInput.addEventListener('touchend', (e) => {
                    e.preventDefault();
                    seedInput.focus();
                });

                buttonsGenerated = true;
            }

            // Function to remove utility buttons
            function removeUtilityButtons() {
                if (generateButton) document.body.removeChild(generateButton);
                if (seedInput) document.body.removeChild(seedInput);
                if (loadSeedButton) document.body.removeChild(loadSeedButton);
                if (copySeedButton) document.body.removeChild(copySeedButton);
                if (solverButton) document.body.removeChild(solverButton);
                if (retryButton) document.body.removeChild(retryButton);
                generateButton = null;
                seedInput = null;
                loadSeedButton = null;
                copySeedButton = null;
                solverButton = null;
                retryButton = null;
            }

            // Game classes
            class Box {
                constructor(x, y) {
                    this.box = add([
                        pos(x, y),
                        boxSprite,
                        area(),
                        scale(gridSquareSize / 24),
                        "box",
                    ]);
                }
                get pos() { return this.box.pos; }
                set pos(newPos) { this.box.pos = newPos; }
            }

            class Unmovable {
                constructor(x, y) {
                    this.unmovable = add([
                        pos(x, y),
                        wallSprite,
                        area(),
                        scale(gridSquareSize / 24),
                        "unmovable",
                    ]);
                }
                get pos() { return this.unmovable.pos; }
                set pos(newPos) { this.unmovable.pos = newPos; }
            }

            class WinBlock {
                constructor(x, y) {
                    this.winBlock = add([
                        pos(x, y),
                        winSprite,
                        area(),
                        scale(gridSquareSize / 24),
                        "winBlock",
                    ]);
                }
                get pos() { return this.winBlock.pos; }
                set pos(newPos) { this.winBlock.pos = newPos; }
            }

            // Game functions
            function initializeLevel(state) {
                // Create game objects
                player = add([
                    pos(state.player.x + offsetX, state.player.y + offsetY),
                    playerSprite,
                    area(),
                    scale(gridSquareSize / 24),
                ]);
                
                boxes = state.boxes.map(b => 
                    new Box(b.x + offsetX, b.y + offsetY)
                );
                
                unmovables = state.walls.map(w =>
                    new Unmovable(w.x + offsetX, w.y + offsetY)
                );
                
                winBlock = new WinBlock(state.goal.x + offsetX, state.goal.y + offsetY);

                // Store initial state for reset functionality
                initialState = JSON.parse(JSON.stringify(state));

                // Create menu button and utility buttons after level is loaded
                if (menuButton) {
                    menuButton.style.display = 'block';
                }
                createUtilityButtons();
            }

            function createLevel(seed = undefined) {
                // Reset game state
                isSolutionRunning = false;
                gameCompleted = false;
                
                // Clean up existing level
                if (player) destroy(player);
                boxes?.forEach(b => destroy(b.box));
                unmovables?.forEach(u => destroy(u.unmovable));
                if (winBlock) destroy(winBlock.winBlock);
                if (winText) {
                    destroy(winText);
                    winText = null;
                }

                // Always try to use cache first unless a specific seed is requested
                if (seed === undefined && levelCache.length > 0) {
                    const cachedLevel = levelCache.shift();
                    console.log(`📦 Using cached level (Cache: ${levelCache.length}/${MAX_CACHED_LEVELS})`);
                    currentLevel = cachedLevel.state;
                    currentSeed = cachedLevel.seed;
                    initializeLevel(currentLevel);
                    
                    // Request new background level to maintain cache
                    requestBackgroundLevel(gridWidth, gridHeight, gridSquareSize);
                    return;
                }

                // If we reach here, either a specific seed was requested or cache was empty
                if (seed !== undefined) {
                    console.log(`🎲 Generating level with specific seed: ${seed}`);
                } else {
                    console.log('⚠️ Cache empty, generating new level...');
                }

                // Show loading animation if needed
                if (!get("loadingAnim").length) {
                    loadingElements.anim = add([
                        sprite("loading"),
                        pos(width() / 2, height() / 2),
                        anchor("center"),
                        scale(10),
                        z(101),
                        "loadingAnim",
                        {
                            add() {
                                this.play("spin");
                            }
                        }
                    ]);
                }

                // Generate new level
                levelWorker.postMessage({
                    width: gridWidth,
                    height: gridHeight,
                    gridSize: gridSquareSize,
                    options: {
                        minPushes: 5,
                        minWalls: 2,
                        maxWalls: 5,
                        minBoxes: 1,
                        maxBoxes: 2
                    },
                    seed: seed || Math.random()
                });
            }

            // Handle worker response
            levelWorker.onmessage = (e) => {
                if (e.data.success) {
                    const { state } = e.data.result;
                    currentLevel = state;
                    currentSeed = e.data.seed;
                    initializeLevel(state);

                    // Remove loading screen with fade
                    tween(
                        1,
                        0,
                        0.5,
                        (val) => {
                            get().forEach((obj) => {
                                if (obj.z >= 100) {
                                    obj.opacity = val;
                                }
                            });
                        },
                        () => {
                            destroyLoadingScreen();
                        }
                    );
                } else {
                    console.error("Level generation failed:", e.data.error);
                    createLevel(Math.random());
                }
            };

            // Update createLevel to clean up buttons
            const originalCreateLevel = createLevel;
            createLevel = (seed) => {
                if (solutionInterval) {
                    clearInterval(solutionInterval);
                    solutionInterval = null;
                }
                // Pass seed through without defaulting to random here
                originalCreateLevel(seed);
            };

            function movePlayer(dx, dy) {
                const newX = player.pos.x + dx * gridSquareSize;
                const newY = player.pos.y + dy * gridSquareSize;
                const boxAtNewPos = boxes.find(b => b.pos.x === newX && b.pos.y === newY);
                const unmovableAtNewPos = unmovables.find(u => u.pos.x === newX && u.pos.y === newY);

                if (boxAtNewPos) {
                    const newBoxX = boxAtNewPos.pos.x + dx * gridSquareSize;
                    const newBoxY = boxAtNewPos.pos.y + dy * gridSquareSize;  // Changed from dx to dy
                    const anotherBox = boxes.find(b => b.pos.x === newBoxX && b.pos.y === newBoxY);
                    const unmovableAtBoxNewPos = unmovables.find(u => u.pos.x === newBoxX && u.pos.y === newBoxY);
                    const boxOverlapsGoal = newBoxX === winBlock.pos.x && newBoxY === winBlock.pos.y;
                    const boxInBounds =
                        newBoxX >= offsetX &&
                        newBoxX < offsetX + gridWidth &&
                        newBoxY >= offsetY &&
                        newBoxY < offsetY + gridHeight;

                    if (!anotherBox && !unmovableAtBoxNewPos && !boxOverlapsGoal && boxInBounds) {
                        boxAtNewPos.pos.x = newBoxX;
                        boxAtNewPos.pos.y = newBoxY;
                        player.pos.x = newX;
                        player.pos.y = newY;
                    }
                } else if (
                    !unmovableAtNewPos &&
                    newX >= offsetX &&
                    newX < offsetX + gridWidth &&
                    newY >= offsetY &&
                    newY < offsetY + gridHeight
                ) {
                    player.pos.x = newX;
                    player.pos.y = newY;
                }
            }

            // Modify the handleWin function
            function handleWin() {
                gameCompleted = true;
                destroy(player);
                player = null;
                
                // Add win text
                winText = add([
                    text("You Win!", { 
                        size: 48,
                        font: "arial"
                    }),
                    pos(windowWidth / 2, windowHeight / 2 - 40),
                    anchor("center"),
                    color(255, 255, 255),
                ]);
                
                // Add next level button using the same style as sidebar buttons
                const nextLevelButton = document.createElement('button');
                nextLevelButton.innerText = 'Next Level';
                nextLevelButton.style.position = 'absolute';
                nextLevelButton.style.left = '50%';
                nextLevelButton.style.top = '50%';
                nextLevelButton.style.transform = 'translate(-50%, 40px)'; // Center horizontally and offset from win text
                styleButton(nextLevelButton, '#28a745');
                document.body.appendChild(nextLevelButton);

                nextLevelButton.addEventListener('click', () => {
                    document.body.removeChild(nextLevelButton);
                    destroy(winText);
                    createLevel(undefined); // Use cached level for next level
                    refocusGame();
                });
            }

            // Update resetLevel function
            function resetLevel() {
                gameCompleted = false;
                isSolutionRunning = false;
                
                if (solutionInterval) {
                    clearInterval(solutionInterval);
                    solutionInterval = null;
                }
                
                // Clear any existing error messages
                get("solutionError").forEach(obj => destroy(obj));
                
                if (player) destroy(player);
                boxes?.forEach(b => destroy(b.box));
                if (winText) {
                    destroy(winText);
                    winText = null;
                }
                
                // Use stored initial state
                player = add([
                    pos(initialState.player.x + offsetX, initialState.player.y + offsetY),
                    playerSprite,
                    area(),
                    scale(gridSquareSize / 24),
                ]);
                
                boxes = initialState.boxes.map(b => 
                    new Box(b.x + offsetX, b.y + offsetY)
                );
            }

            // Sprite setup
            const boxSprite = sprite("box");
            const wallSprite = sprite("wall");
            const winSprite = sprite("tempTileMap", { frame: 5 });
            const playerSprite = sprite("playerSheet", {frame: 0});
            const tileSprite = sprite("tile");

            playerSprite.play("idle");

            // Create grid
            for (let i = 0; i < gridSizeX; i++) {
                for (let j = 0; j < gridSizeY; j++) {
                    add([
                        pos(offsetX + gridSquareSize * i, offsetY + gridSquareSize * j),
                        tileSprite,
                        scale(gridSquareSize / 24),
                        z(-1),
                        "gridTile"
                    ]);
                }
            }

            // Event listeners (replace existing section)
            onKeyPress(["d", "right"], () => {
                if (isSolutionRunning || gameCompleted) return;
                movePlayer(1, 0);
                player.flipX = false;
            });

            onKeyPress(["a", "left"], () => {
                if (isSolutionRunning || gameCompleted) return;
                movePlayer(-1, 0);
                player.flipX = true;
            });

            onKeyPress(["w", "up"], () => {
                if (isSolutionRunning || gameCompleted) return;
                movePlayer(0, -1);
            });

            onKeyPress(["s", "down"], () => {
                if (isSolutionRunning || gameCompleted) return;
                movePlayer(0, 1);
            });

            onUpdate(() => {
                if (player && player.pos.x === winBlock.pos.x && player.pos.y === winBlock.pos.y) {
                    handleWin();
                }
            });

            // Initialize level and handle loading screen
            function initGame() {
                // Calculate grid measurements first
                windowWidth = width();
                windowHeight = height();
                gridSquareSize = Math.floor(windowHeight / (gridSizeY + 0.5));
                gridWidth = gridSizeX * gridSquareSize;
                gridHeight = gridSizeY * gridSquareSize;
                offsetX = (windowWidth - gridWidth) / 2;
                offsetY = gridSquareSize * 0.25;

                // Create grid
                for (let i = 0; i < gridSizeX; i++) {
                    for (let j = 0; j < gridSizeY; j++) {
                        add([
                            pos(offsetX + gridSquareSize * i, offsetY + gridSquareSize * j),
                            tileSprite,
                            scale(gridSquareSize / 24),
                            z(-1),
                        ]);
                    }
                }

                // Now start background level generation with proper measurements
                for (let i = 0; i < MAX_CACHED_LEVELS; i++) {
                    requestBackgroundLevel(gridWidth, gridHeight, gridSquareSize);
                }

                // Initialize first level
                createLevel();
            }

            // Start game initialization after brief delay
            wait(0.1, initGame);

            // Remove duplicate window resize listeners and combine into one
            window.addEventListener("resize", () => {
                wait(0.1, () => {
                    updateGridMeasurements();
                    if (menuButton) {
                        menuButton.pos.y = windowHeight - 40;
                    }
                });
            });

            // Add fullscreen and visibility change handlers
            document.addEventListener('fullscreenchange', () => {
                wait(0.1, updateGridMeasurements); // Small delay to ensure new dimensions are available
            });

            document.addEventListener('visibilitychange', () => {
                if (!document.hidden) {
                    wait(0.1, updateGridMeasurements);
                }
            });

            // Clean up event listeners when leaving scene
            onSceneLeave(() => {
                document.removeEventListener('fullscreenchange', updateGridMeasurements);
                document.removeEventListener('visibilitychange', updateGridMeasurements);
                levelWorker.terminate();
                destroyLoadingScreen();
                if (menuButton && document.body.contains(menuButton)) {
                    document.body.removeChild(menuButton);
                }
                removeUtilityButtons();
            });

            // Remove the existing onResize handler since we're using the window event listener
        });

        // Clean up worker when leaving scene
        onSceneLeave(() => {
            levelWorker.terminate();
            destroyLoadingScreen();
            removeUtilityButtons();
            if (menuButton && document.body.contains(menuButton)) {
                document.body.removeChild(menuButton);
            }
        });
    });
}
